import classnames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { fontSizes } from '../../theme';
import { colors } from '../../theme/colors';

interface GenericObject {
  [key: string]: string;
}
export const textColors: GenericObject = {
  active: colors.brand[400],
  alert: colors.fire[400],
  dark: colors.steel[500],
  default: colors.steel[400],
  disabled: colors.steel[300],
  flow_title: colors.steel[500],
  error: colors.fire[600],
  light: colors.steel[300],
  transparent: 'transparent',
  warning: colors.fire[400],
  white: '#ffffff',
  primary: colors.steel[500],
  secondary: colors.steel[400],
};

export type HexColor = string;
export type TextSizes = 'small' | 'regular' | 'large' | 'extraLarge';
export type TextColors =
  | 'active'
  | 'alert'
  | 'dark'
  | 'default'
  | 'disabled'
  | 'error'
  | 'light'
  | 'warning'
  | 'white'
  | 'inherit';
type TextDecoration = 'underline' | 'strikethrough' | 'none';
export type TextWeights = 300 | 400 | 500 | 600 | 700;

interface TextProps {
  breakword?: boolean;
  children: React.ReactNode;
  color?: TextColors | HexColor;
  display?: 'inline' | 'block';
  id?: string;
  marginBottom?: number;
  marginTop?: number;
  nowrap?: boolean;
  prewrap?: boolean;
  pre?: boolean;
  size?: TextSizes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  tabIndex?: number;
  textAlign?: 'center' | 'left' | 'right';
  textDecoration?: TextDecoration;
  /**
   * @deprecated Warning - this conflicts with Tailwind's Truncate classname.
   */
  truncate?: boolean;
  weight?: TextWeights;
  testId?: string;
  /**
   * Warning - this may conflict with existing styles in this component.
   */
  className?: string;
  title?: string;
}

export function Text(props: TextProps): JSX.Element {
  const {
    breakword = false,
    children,
    color = 'primary',
    display = 'block',
    id,
    marginBottom = 0,
    marginTop = 0,
    nowrap = false,
    prewrap = false,
    pre = false,
    size = 'regular',
    style = {},
    tabIndex,
    textAlign = 'left',
    textDecoration = 'none',
    truncate = false,
    weight = '400',
    testId,
    className,
    title,
  } = props;

  const classNames = classnames(
    {
      alignLeft: textAlign === 'left',
      alignCenter: textAlign === 'center',
      alignRight: textAlign === 'right',
      breakword: breakword === true,
      extraLarge: size === 'extraLarge',
      large: size === 'large',
      nowrap: nowrap === true,
      prewrap: prewrap === true,
      pre: pre === true,
      regular: size === 'regular',
      small: size === 'small',
      text: true,
      truncate: truncate === true,
      underline: textDecoration === 'underline',
    },
    className,
  );

  const textColor = textColors[color] || color;

  return (
    <StyledText
      id={id}
      tabIndex={tabIndex}
      className={classNames}
      data-testid={testId}
      style={{
        ...style,
        fontWeight: weight,
        color: textColor,
        marginBottom,
        marginTop,
        display,
      }}
      title={title}
    >
      {children}
    </StyledText>
  );
}

const StyledText = styled.span`
  font-family: 'Open Sans', sans-serif;

  &.regular {
    font-size: ${fontSizes.regular.fontSize};
    line-height: ${fontSizes.regular.lineHeight};
  }
  &.small {
    font-size: ${fontSizes.small.fontSize};
    line-height: ${fontSizes.small.lineHeight};
  }
  &.large {
    font-size: ${fontSizes.large.fontSize};
    line-height: ${fontSizes.large.lineHeight};
  }
  &.extraLarge {
    font-size: ${fontSizes.extraLarge.fontSize};
    line-height: ${fontSizes.extraLarge.lineHeight};
  }
  &.alignLeft {
    text-align: left;
  }
  &.alignRight {
    text-align: right;
  }
  &.alignCenter {
    text-align: center;
  }
  &.underline {
    text-decoration: underline;
  }
  /* Warning - this is sharing the same classname as Tailwind's Truncate. */
  &.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-word;
  }
  &.breakword {
    word-break: break-word;
  }
  &.nowrap {
    white-space: nowrap;
  }
  &.prewrap {
    white-space: pre-wrap;
  }
  &.pre {
    white-space: pre;
  }
  a {
    cursor: pointer;
    color: ${colors.brand[400]};
    text-decoration: underline !important;
  }
  a:hover {
    color: #3288ff;
  }
  strong {
    font-weight: 600;
    color: #000000;
  }
  em {
    font-style: italic;
  }
  p {
    margin: 0;
  }
  p + p {
    margin-top: 12px;
  }
`;
