const PROD_API_GATEWAY_URL = 'https://prod-api-gateway.newfront.com';

module.exports = {
  FAVICON_ENV: 'production',
  KEYCLOAK: {
    CLIENT_ID: 'newfront-web',
    REALM: {
      CLIENTS: 'newfront-clients',
      EMPLOYEES: 'newfront',
    },
  },
  API: {
    ACCOUNTS: 'https://v2-prod-api-account.prod.newfront.com',
    ADDRESS: 'https://v2-prod-api-address.prod.newfront.com',
    AUTH: 'https://api-auth.newfront.com/auth',
    BENJI_SLACK: 'https://v2-prod-benji-slack.prod.newfront.com',
    BULK_IMPORT: 'https://v2-prod-api-bulk-import.prod.newfront.com',
    CASE_MANAGEMENT: 'https://v2-prod-api-case-mgmt.prod.newfront.com',
    CLAIMS: PROD_API_GATEWAY_URL,
    CONVERSION: 'https://v2-prod-api-conversion.prod.newfront.com',
    COVERAGE: 'https://v2-prod-api-coverage.prod.newfront.com',
    DOCUMENT_EXTRACTION: 'https://v2-prod-api-doc-extr.prod.newfront.com',
    DOCUMENT_RENDERING: 'https://v2-prod-api-doc-rendering.prod.newfront.com',
    DOD: 'https://v2-prod-api-dod.prod.newfront.com',
    EMAIL_LOGGER: 'https://v2-prod-api-email-logger.prod.newfront.com',
    EXPOSURES: 'https://v2-prod-api-exposures.prod.newfront.com',
    FILES: 'https://v2-prod-api-files.prod.newfront.com',
    LEAD: 'https://api-lead.newfront.com',
    NEWFRONTAPP: `${PROD_API_GATEWAY_URL}/newfrontapp`,
    NOTIFICATIONS: 'https://v2-prod-api-ntfns.prod.newfront.com',
    PAYMENTS: 'https://v2-prod-api-payments.prod.newfront.com',
    PROOF_OF_INSURANCE: 'https://v2-prod-api-poi.prod.newfront.com',
    PROPOSAL: 'https://v2-prod-api-proposal.prod.newfront.com',
    QUOTING: 'https://v2-prod-api-quoting.prod.newfront.com',
    SEARCH: 'https://v2-prod-api-search.prod.newfront.com',
    TASK: 'https://v2-prod-api-task.prod.newfront.com',
    TOTAL_REWARDS: 'https://v2-prod-api-total-rewards.prod.newfront.com',
    TRADING_PARTNERS: 'https://v2-prod-api-tp.prod.newfront.com',
    USERS: 'https://v2-prod-api-users.prod.newfront.com',
  },
  SUB_APPS: {
    BULK_IMPORT: 'https://bulk-import.newfront-preview.com/import',
    CERTIFICATES: 'https://proof-of-insurance.newfront-preview.com/certificates',
    CLAIMS: 'https://claims.newfront-preview.com/claims-app',
    CONVERSION: 'https://conversion.newfront-preview.com/conversion',
    COVERAGE: 'https://coverage.newfront-preview.com/admin/coverage',
    EXPOSURES: 'https://exposures.newfront-preview.com/exposures',
    FILES: 'https://files-app-v2.newfront-preview.com/files',
    PAYMENTS: 'https://payments.newfront-preview.com/payments',
    PROPOSAL: 'https://proposals.newfront-preview.com/proposal',
    QUOTING: 'https://quoting.newfront-preview.com/quoting',
    TRADING_PARTNERS: 'https://trading-partners.newfront-preview.com/trading-partners',
  },
  SENTRY: {
    ENABLED: true,
    ENVIRONMENT: 'production',
    DSN: {
      ACCOUNTS: 'https://a4b95d3e05a54e7e92912e67fad5cb16@o570074.ingest.sentry.io/6180684',
      CERTIFICATES: 'https://cad5ce96ab82430e8c59d739407012d9@o570074.ingest.sentry.io/6591477',
      CLIENT_DASH: 'https://1ce8be9f2df340cfbcb3639c27655f04@o570074.ingest.sentry.io/6228996',
      CONVERSION: 'https://a590b4991e17407e8f03fd24bf5faab5@o570074.ingest.sentry.io/6242079',
      FILES: 'https://46938d1238fb427d971cce80f46e77f4@o570074.ingest.sentry.io/5718276',
      PAYMENTS: 'https://619f96f5916f4af5b88cdefb4b820076@o570074.ingest.sentry.io/6326832',
      PROPOSAL: 'https://b50eb2239d6d43a2ad2f5c5bb5da2cb5@o570074.ingest.sentry.io/4504119010590720',
      QUOTING: 'https://084b05dd86324446b04fd5ef2b1b00dc@o570074.ingest.sentry.io/6271695',
      TRADING_PARTNERS: 'https://9694634ff62d46a4aa373cd30bf74423@o570074.ingest.sentry.io/6748065',
    },
  },
  LINKS: {
    BULK_IMPORT_APP: 'https://dash.newfront.com/import',
    CERTIFICATES_APP: 'https://dash.newfront.com/certificates',
    CDN_BASE_URL: 'https://cdn.newfront.com',
    CLAIMS_APP: 'https://dash.newfront.com/claims-app',
    CLIENT_DASH_APP: 'https://app.newfront.com',
    CONVERSION_APP: 'https://dash.newfront.com/conversion',
    COVERAGE_APP: 'https://dash.newfront.com/admin/coverage',
    DASH_APP: 'https://dash.newfront.com',
    EXPOSURES_APP: 'https://dash.newfront.com/exposures',
    FILES_APP: 'https://dash.newfront.com/files',
    HEX_IP_APP: 'https://app.hex.tech/6fc62e10-586d-466c-9272-b49abe3ecaed/app/d2c717fe-f2e6-4c1d-a975-3ba27f1fcf8e',
    HEX_SOI_APP: 'https://app.hex.tech/6fc62e10-586d-466c-9272-b49abe3ecaed/app/21360a87-7963-4e00-8ade-ce0a64c25499',
    INDIO: 'https://newfront.useindio.com',
    LEGACY_DASH_APP: 'https://legacy.newfront.com',
    MARKETING_SITE: 'https://www.newfront.com',
    PAYMENTS_APP: 'https://dash.newfront.com/payments',
    PROPOSAL_APP: 'https://app.newfront.com/proposal',
    QUOTING_APP: 'https://dash.newfront.com/quoting',
    SERVICECLOUD_URL: 'https://newfront.lightning.force.com',
    TECHCANARY_URL: 'https://newfronttc.my.salesforce.com',
    TRADING_PARTNERS_APP: 'https://dash.newfront.com/trading-partners',
  },
  LAUNCH_DARKLY: {
    PROJECTS: {
      CLIENT_DASH: '62472238978a2f1519421058',
      ADMIN_DASH: '5e5fef5aa7ff490a8b054bf9',
    },
  },
  BEAMER: {
    PRODUCT_ID: 'qRKvmOeH53231',
    CLIENT_PRODUCT_ID: 'ocuAZfEK61892',
  },
  PLAID: {
    ENVIRONMENT: 'https://production.plaid.com',
  },
  SEGMENT: {
    SOURCES: {
      CLIENT_DASH: 'cng8TguLtFiGRMcjAn3xPE5e4aVMGKbs',
      ADMIN_DASH: 'UT9VXJwvqzHFrdIfUzj3X6a9oSGgdN5m',
    },
  },
  DATADOG: {
    ENABLE_CONSOLE_LOGGING: false,
    ENABLE_DATADOG_LOGGING: true,
    RUM: {
      ACCOUNTS: {
        APPLICATION_ID: '74076418-031d-4809-bbec-d563c4261ce8',
        CLIENT_TOKEN: 'pub1b939788303c32fe8f0a68450cc63ab9',
      },
      CLIENT_DASH: {
        APPLICATION_ID: '96e1f2df-f0b8-40ab-9d65-4b56f9cc124b',
        CLIENT_TOKEN: 'pubb25472edde35170de68b8d84f038531b',
      },
      QUOTING: {
        APPLICATION_ID: 'c491f3a2-4e90-4b66-aa08-66501573f9e5',
        CLIENT_TOKEN: 'pub02344942f3eb02bd4d44543128bbc897',
      },
      PROOF_OF_INSURANCE: {
        APPLICATION_ID: 'f6a07a45-c726-48ec-915d-543646fef866',
        CLIENT_TOKEN: 'pubef56d2cef5038808ecdb32617b22a070',
      },
      TRADING_PARTNERS: {
        APPLICATION_ID: '459cb0de-c837-4f00-977f-659524dae5da',
        CLIENT_TOKEN: 'puba445a7a99f0d5de0770f141bb08392e0',
      },
      PAYMENTS: {
        APPLICATION_ID: '90e8c0c6-6f68-4347-b22c-b2ac6fa77e74',
        CLIENT_TOKEN: 'pub105c8c35d1a34eedf1a3427886edfb05',
      },
      CLAIMS: {
        APPLICATION_ID: '00890c05-9114-4dbe-99f6-da741286944e',
        CLIENT_TOKEN: 'pubff460acf143a20a162c60a855b2b0553',
      },
      CONVERSION: {
        APPLICATION_ID: '0755d735-a909-4ac2-819b-0747023706cb',
        CLIENT_TOKEN: 'pub8b9dc2f9ef1a729184dd7795c4d75257',
      },
      BULK_IMPORT: {
        APPLICATION_ID: '30e56e4c-d41a-4169-beb8-4486106b5400',
        CLIENT_TOKEN: 'puba971403ad2035e4ec2ca7ea17660ad07',
      },
      FILES: {
        APPLICATION_ID: '6c16d41a-9cf0-442b-9a00-c0e4f8240a7d',
        CLIENT_TOKEN: 'pubd005b9ce9d43f85f1116a12d57684c8e',
      },
      PROPOSAL: {
        APPLICATION_ID: '9d534c39-43ea-4172-b0f8-931d9bd12053',
        CLIENT_TOKEN: 'pub6bf87cd9b9506422a8f7863c0a8bc871',
      },
    },
  },
  DEFAULT_USERS: {
    ACCOUNT_MANAGER: 'b9485294-6014-4395-9b76-f919e5cd54fe',
    PRODUCER: '161c83a6-b941-4064-908a-8df6aecabcda',
    BOR_ACCOUNT_MANAGER: '004b1cb1-3f7b-4832-9b8d-abe5438510f1',
  },
};
