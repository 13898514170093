// Helpers
export { HttpMethod } from './types/http';
export { SSRBoundary } from './ssr-boundary';

// trpc
export { createTRPCClient } from './trpc/create';
export { useClientQueryHooks } from './trpc/hooks';
export type { RouterUseQueryOptions, RouterUseMutationOptions, TRPCClientHooks } from './trpc/types';

// Analytics
export {
  AnalyticsProvider,
  useAnalytics,
  HeartbeatCollector,
  HeartbeatEnrichment,
  useHeartbeatEnrichment,
  HeartbeatIdentifier,
} from './analytics';
export type { TrackEvent, Properties } from './analytics';

// Next API error
export { NextApiError, isNextApiError, isNextApiErrorDtoLike, isNextApiErrorLike } from './legacy/next-api-error/error';

// Error boundary
export { NextApiErrorBoundary } from './legacy/error-boundary/next-api-error-boundary';
export { ErrorBoundary } from './legacy/error-boundary/error-boundary';
export { ErrorOverlay } from './legacy/error-boundary/error-overlay';

// Create fetch
export { createFetch } from './fetch';

// Errors
export { HTTPError } from './errors/http-error';
export { NetworkError } from './errors/network';
export { TimeoutError } from './errors/timeout';
export { PermissionError } from './errors/permission';
export { NotFoundError } from './errors/not-found';
export { NotLoggedInError } from './errors/logged-in';
export {
  isNotFoundError,
  isNotLoggedInError,
  isPermissionError,
  isTimeoutError,
  getPreferredErrorMessage,
  isDataLayerError,
  isTRPCClientError,
  isTRPCErrorResponse,
  isApplicationErrorCode,
  isHTTPError,
  isNetworkError,
  getApplicationError,
} from './errors/utils';
export type { DataLayerError } from './errors/utils';
