import styled, { css, keyframes } from 'styled-components';

import { IconCircleSpinner, IconNewfrontLogo } from '../icons';
import { Text } from '../text';

interface DefaultSpinnerProps {
  bgColor?: string;
  color?: string;
  relative?: boolean;
  variant?: 'small' | 'default';
  height?: number;
  testId?: string;
}

/**
 * Default spinner (newfront-app)
 */

export function Spinner({
  color,
  bgColor,
  relative,
  variant = 'default',
  height,
  testId,
}: DefaultSpinnerProps): JSX.Element {
  return (
    <StyledDefaultSpinner
      color={color}
      bgColor={bgColor}
      relative={relative}
      variant={variant}
      height={height}
      data-testid={testId}
    >
      <span />
      <span />
      <span />
    </StyledDefaultSpinner>
  );
}

interface CircleSpinnerProps {
  label?: string;
  size?: 12 | 16 | 24 | 32 | 40;
  testId?: string;
}

/**
 * Newfront logo spinner (quoting-app)
 */

export function CircleSpinnerInline(props: CircleSpinnerProps): JSX.Element {
  const { label, size, testId } = props;
  return (
    <StyledCircleSpinnerInline data-testid={testId}>
      <div className="flex items-center">
        {label && <Text>{label}</Text>}
        <IconCircleSpinner size={size} />
      </div>
    </StyledCircleSpinnerInline>
  );
}

export function CircleSpinner(props: CircleSpinnerProps): JSX.Element {
  const { label, size, testId } = props;
  return (
    <StyledCircleSpinner data-testid={testId}>
      <CircleSpinnerInline size={size} label={label} />
    </StyledCircleSpinner>
  );
}

const Bounce = keyframes`
  from {
    opacity: 1;
    transform: translateY(4px);
  }
  to {
    opacity: 0.1;
    transform: translateY(-4px);
  }
`;

const Spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const StyledDefaultSpinner = styled.span<DefaultSpinnerProps>`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor || 'none'};
  display: flex;
  height: ${({ variant, height }) => (variant === 'small' ? '12px' : `${height}px` || '16px')};
  justify-content: center;
  position: relative;

  ${({ relative }) =>
    !relative &&
    css`
      left: 50%;
      position: absolute;
      top: calc(50%);
      transform: translate(-50%, -50%);
    `}

  > span {
    animation: ${Bounce} 0.6s infinite alternate-reverse;
    background-color: ${({ color }) => color || 'currentColor'};
    border-radius: 50%;
    display: block;
    height: ${({ variant }) => (variant === 'small' ? '4px' : '6px')};
    margin: ${({ variant }) => (variant === 'small' ? '0 1px' : '0 3px')};
    width: ${({ variant }) => (variant === 'small' ? '4px' : '6px')};
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  > span:nth-child(2) {
    animation-delay: 0.2s;
  }

  > span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const StyledCircleSpinnerInline = styled.div`
  svg {
    animation: ${Spin} 600ms infinite linear;
    display: block;
    margin-left: 4px;
  }
`;

const StyledCircleSpinner = styled.div<CircleSpinnerProps>`
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(50% - 20px);
`;

/**
 * Show a loading state for the entire application. This should be used while the app is initializing and loading the
 * session and any other resource it needs to render the first part of the UI.
 */
export function LogoSpinner(): JSX.Element {
  return (
    <StyledLogoSpinnerContainer>
      <StyledLogoSpinner>
        <IconNewfrontLogo size={40} color="red" />
      </StyledLogoSpinner>
    </StyledLogoSpinnerContainer>
  );
}

const LogoSpinnerAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledLogoSpinnerContainer = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledLogoSpinner = styled.div`
  animation: ${LogoSpinnerAnimation} 1.5s infinite ease-in-out;
`;
