import { browserLogger } from '@newfront-insurance/dd-rum';
import type { DecodedJwt } from '@newfront-insurance/next-auth-api-types';
import type { KeycloakInitOptions, KeycloakConfig } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import qs from 'qs';
import { TinyEmitter } from 'tiny-emitter';

import { parseTokenPayload } from '../internal/helpers/jwt';
import type {
  AuthClient,
  AuthClientPersistor,
  AuthClientTokens,
  AuthState,
  SubscriptionCallback,
  Tokens,
  UnsubscribeCallback,
} from '../types';

interface KeycloakLoginOptions {
  idpHint?: string;
  redirectUri?: string;
}

/**
 * Keycloak needs to be initialised. It is not usable until then, so we'll keep track
 * of the status of the keycloak instance.
 */
enum KeycloakAuthClientState {
  CREATED = 'CREATED',
  READY = 'READY',
}

export interface KeycloakAuthClientOptions {
  loginOptions: KeycloakLoginOptions;
  config: KeycloakConfig;
  initOptions: KeycloakInitOptions;
  tokenPersistor: AuthClientPersistor;
}

function buildRedirectUrl(redirectUrl?: string): string | undefined {
  if (!redirectUrl) {
    return;
  }
  const fullUrl = redirectUrl.startsWith('/') ? `${window.location.origin}${redirectUrl}` : redirectUrl;
  return fullUrl;
}

interface TokenResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: 'Bearer';
  'not-before-policy': number;
  session_state: string;
  scope: string;
}

/**
 * This is an auth client for Keycloak. It will automatically start the keycloak
 * service and will prevent it from breaking during SSR.
 */
export class KeycloakAuthClient implements AuthClient {
  keycloak!: Keycloak;

  private readonly initOptions: KeycloakInitOptions;

  private readonly loginOptions: KeycloakLoginOptions;

  private readonly config: KeycloakConfig;

  private readyState!: KeycloakAuthClientState;

  private initPromise!: Promise<boolean> | null;

  private refreshTokenScheduleTimeout?: NodeJS.Timeout;

  persistor: AuthClientPersistor;

  emitter: TinyEmitter;

  constructor(options: KeycloakAuthClientOptions) {
    this.config = options.config;
    this.initOptions = options.initOptions;
    this.loginOptions = options.loginOptions;

    // Create an emitter. Add event handlers to keycloak so there can be multiple subscribers
    this.emitter = new TinyEmitter();

    // Storing the tokens in storage
    this.persistor = options.tokenPersistor;

    // On these events we'll want to update the tokens.
    this.emitter.on('onReady', this.onAuthStateChange);
    this.emitter.on('onAuthSuccess', this.onAuthStateChange);
    this.emitter.on('onTokenRefresh', this.onAuthStateChange);
    this.emitter.on('onAuthRefreshSuccess', this.onAuthRefreshSuccess);
    this.emitter.on('onLogout', this.onAuthStateChange);
    this.emitter.on('onLogin', this.onAuthStateChange);

    // Auto-refresh the token when it expires
    this.emitter.on('onTokenExpired', this.onTokenExpired);

    // Initial state
    this.reset();
  }

  /**
   * Reset the keycloak instance.
   */
  private reset(): void {
    this.readyState = KeycloakAuthClientState.CREATED;
    this.initPromise = null;
    this.keycloak = new Keycloak(this.config);
    this.keycloak.onAuthError = (error) => this.emitter.emit('onAuthError', error);
    this.keycloak.onAuthLogout = () => this.emitter.emit('onLogout');
    this.keycloak.onAuthRefreshError = () => this.emitter.emit('onAuthRefreshError');
    this.keycloak.onAuthRefreshSuccess = () => this.emitter.emit('onAuthRefreshSuccess');
    this.keycloak.onAuthSuccess = () => this.emitter.emit('onAuthSuccess');
    this.keycloak.onReady = () => this.emitter.emit('onReady');
    this.keycloak.onTokenExpired = () => this.emitter.emit('onTokenExpired');
  }

  /**
   * This is called whenever the auth state might change. We use this as a callback
   * on many of the events emitted by Keycloak.
   */
  private readonly onAuthStateChange = (): void => {
    const authState = this.getAuthState();
    const tokens = this.serializeState();
    this.persistor.set(tokens);
    this.emitter.emit('onAuthStateChange', authState);
  };

  /**
   * Attempts to refresh token 30 secs before it actually expires
   * We'll use a setTimeout since the underlying Keycloak library doesn't handle it
   */
  private readonly onAuthRefreshSuccess = (): void => {
    const authState = this.getAuthState();

    if (!authState.token) {
      return;
    }

    this.scheduleTokenRefresh(authState.token);
  };

  /**
   * Refresh the tokens.
   */
  private readonly onTokenExpired = async (): Promise<void> => {
    await this.updateTokens();
  };

  /**
   * Get the current auth state from the auth client.
   */
  getAuthState(): AuthState {
    const { token, idToken } = this.keycloak;
    const isReady = this.readyState === KeycloakAuthClientState.READY;
    const isLoggedIn = !!(token && idToken);

    if (isReady && isLoggedIn) {
      if (!this.refreshTokenScheduleTimeout) {
        this.scheduleTokenRefresh(token);
      }

      return {
        state: 'LOGGED_IN',
        isReady,
        isLoggedIn,
        token,
        idToken,
      };
    }

    if (isReady && !isLoggedIn) {
      return {
        state: 'NOT_LOGGED_IN',
        isReady,
        isLoggedIn,
        token: undefined,
        idToken: undefined,
      };
    }

    return {
      state: 'NOT_READY',
      isReady: false,
      isLoggedIn: false,
      token: undefined,
      idToken: undefined,
    };
  }

  /**
   * This will return the keycloak tokens but it will update the tokens if needed, so this
   * function is async.
   */
  async updateTokens(): Promise<Tokens | undefined> {
    if (this.readyState !== KeycloakAuthClientState.READY) {
      return undefined;
    }

    // Update the token if it's going to expire in the next 30 seconds.
    await this.keycloak
      .updateToken(30)
      .catch((err) => {
        this.persistor.clear();

        browserLogger.warn('Failed to update access token.', {
          error: err,
        });
      })
      .then((refreshed) => {
        if (refreshed) {
          this.emitter.emit('onTokenRefresh');
        }
      });
    if (this.keycloak.token && this.keycloak.idToken) {
      return {
        token: this.keycloak.token,
        idToken: this.keycloak.idToken,
      };
    }

    clearTimeout(this.refreshTokenScheduleTimeout);

    this.refreshTokenScheduleTimeout = undefined;

    return undefined;
  }

  /**
   * Get the tokens from keycloak instance.
   */
  private serializeState(): Partial<AuthClientTokens> | undefined {
    if (!this.keycloak.authenticated) {
      return undefined;
    }

    return {
      token: this.keycloak.token,
      idToken: this.keycloak.idToken,
      refreshToken: this.keycloak.refreshToken,
    };
  }

  /**
   * Schedules a token refresh 30 secs before it actually expires.
   * Keycloak.js doesn't handle it automatically yet.
   *
   * https://github.com/keycloak/keycloak/issues/16746
   *
   * @param currentToken
   * @param minValidity the buffer time in seconds before the token expires
   */
  private scheduleTokenRefresh(currentToken: string, minValidity = 30): void {
    const decodedJwt = parseTokenPayload<DecodedJwt>(currentToken);
    // timeout in seconds considering 30 secs of buffer
    const timeout = (decodedJwt.exp - decodedJwt.iat - minValidity) * 1000;

    this.refreshTokenScheduleTimeout = setTimeout(async () => {
      await this.updateTokens();
    }, timeout);
  }

  /**
   * Subscribe to auth state changes.
   */
  subscribe(fn: SubscriptionCallback): UnsubscribeCallback {
    this.emitter.on('onAuthStateChange', fn);
    return () => {
      this.emitter.off('onAuthStateChange', fn);
    };
  }

  /**
   * Log the current user out and kill the session.
   * @param redirectUri URL to redirect the user after the logout is complete.
   */
  async logout(redirectUri?: string): Promise<void> {
    await this.keycloak.logout({
      redirectUri,
    });
    this.emitter.emit('onLogout');
  }

  /**
   * Log the user in by redirecting them to the login page.
   * @param redirectUri URL to redirect the user after the login is complete. Defaults to the current page.
   */
  async login(redirectUri?: string): Promise<void> {
    // Keycloak needs .init called before trying to login.
    if (this.readyState !== KeycloakAuthClientState.READY) {
      throw new Error('Keycloak service not started');
    }
    if (!this.keycloak.authenticated) {
      const fullRedirectUrl = buildRedirectUrl(redirectUri || this.loginOptions.redirectUri);
      await this.keycloak
        .login({
          idpHint: this.loginOptions.idpHint,
          redirectUri: fullRedirectUrl,
        })
        .catch((e) => {
          this.persistor.clear();
          throw e;
        });
      this.emitter.emit('onLogin');
    }
  }

  async swap(targetClient: string, swapContext?: Record<string, string>): Promise<Omit<AuthClientTokens, 'idToken'>> {
    if (this.readyState !== KeycloakAuthClientState.READY) {
      throw new Error('Keycloak service not started');
    }

    const response = await fetch(`${this.config.url}/realms/${this.config.realm}/protocol/openid-connect/token`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify({
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        client_id: this.config.clientId,
        audience: targetClient,
        requested_token_type: 'urn:ietf:params:oauth:token-type:refresh_token',
        subject_token: this.keycloak.token,
        ...(swapContext ?? {}),
      }),
    });

    const data: TokenResponse = await response.json();

    return {
      token: data.access_token,
      refreshToken: data.refresh_token,
    };
  }

  /**
   * Start the auth service. This will begin listening to auth state changes and watching
   * for token updates.
   * @returns boolean Did it successfully start?
   */
  async start(): Promise<boolean> {
    // If we've already started the service, just return the existing promise
    if (this.initPromise) {
      return this.initPromise;
    }

    // We can pull the tokens from the sesion to avoid a login redirect
    const cachedTokens = this.persistor.get();

    // Store the promise so that it can only be initialised once.
    // Once it has been started it can't be started again unless
    // you call .stop.
    this.initPromise = this.keycloak
      .init({
        pkceMethod: 'S256',
        ...this.initOptions,
        ...(cachedTokens || {}),
        timeSkew: 0,
        checkLoginIframe: false,
      })
      .then(() => {
        // After keycloak is initialised, if it's not authenticated due to a callback or
        // from pulling the tokens from the cache, we should clear the cache.
        if (!this.keycloak.authenticated) {
          this.persistor.clear();
        }
        this.readyState = KeycloakAuthClientState.READY;
        this.emitter.emit('onReady');
        return true;
      })
      .catch((e) => {
        this.emitter.emit('onInitError', e);
        this.persistor.clear();
        throw e;
      });

    return this.initPromise;
  }

  /**
   * Stop the auth service and reset everything.
   */
  stop(): boolean {
    this.reset();
    return true;
  }
}
