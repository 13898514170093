import * as dateFn from 'date-fns';
import toDate from 'date-fns-tz/toDate';
import { isNumber, get } from 'lodash';
import isObject from 'lodash/isObject';

import type { CalendarDateObject, ISODateString, DateString } from '../types';

export const DATE_STRING_FORMAT = 'yyyy-MM-dd';
export const DATE_STRING_DIVIDER = '-';
export const AUSTRALIA_TIMEZONE = 'Australia/Sydney';
export const UTC_TIMEZONE = 'UTC';
export const LA_TIMEZONE = 'America/Los_Angeles';

export enum DateInputFormat {
  YEAR_FIRST = 'yyyy-MM-dd',
  DAY_FIRST = 'd/M/yyyy',
  AMERICAN = 'MM/dd/yyyy',
}

export const DateInputFormatPlaceholder: Record<DateInputFormat, string> = {
  [DateInputFormat.YEAR_FIRST]: 'YYYY-MM-DD',
  [DateInputFormat.DAY_FIRST]: 'DD/MM/YYYY',
  [DateInputFormat.AMERICAN]: 'MM/DD/YYYY',
};

/**
 * Returns the DateFormat used by the locale.
 * @returns DateFormat
 */
export function getLocalDateInputFormat(): DateInputFormat {
  const { language } = navigator;
  if (language === 'en-US') {
    return DateInputFormat.AMERICAN;
  }
  return DateInputFormat.DAY_FIRST;
}

/**
 * Returns the client local timezone.
 */
export function getLocalTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
}

/**
 * Prepend a number less than 10 with a 0.
 */
export function prependWithZero(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }
  return String(num);
}

/**
 * Takes the legacy format string and returns a new format string used by
 * date-fns v2. This means we not need to update every date picker input.
 * @param format The format string
 * @returns Updated format string
 */
export function toUnicodeDateTokenFormat(format: string): string {
  return format.replace(/D{2}/, 'dd').replace(/D{1}/, 'd').replace(/Y{4}/, 'yyyy').replace(/d{3}/, 'eee');
}

/**
 * Taken from is-iso-date npm package which didn't have types
 * @see https://github.com/honeinc/is-iso-date
 */
const isoDateRegExp =
  // eslint-disable-next-line max-len
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

/**
 * Determine if a give value is a CalendarDate object.
 */
export function isCalendarDateObject(value: unknown): value is CalendarDateObject {
  if (!isObject(value)) {
    return false;
  }
  return isNumber(get(value, 'year')) && isNumber(get(value, 'month')) && isNumber(get(value, 'day'));
}

/**
 * Determine if the string that has been passed in is an ISO string.
 */
export function isISOString(value: unknown): value is ISODateString {
  if (typeof value !== 'string') return false;
  return isoDateRegExp.test(value);
}

/**
 * Check to see if a string is a DateString in the format yyyy-MM-dd.
 */
export function isDateString(value: unknown): value is DateString {
  if (typeof value !== 'string') return false;
  if (value.length !== DATE_STRING_FORMAT.length) return false;
  try {
    const date = toDate(value, {
      timeZone: UTC_TIMEZONE,
    });
    return dateFn.isValid(date);
  } catch (e) {
    return false;
  }
}

/**
 * Returns a string array of months in order.
 */
export function getMonthsInOrder(): string[] {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
}

/**
 * Given a numeric month (1-12), returns the month as a string.
 */
export function getMonthStringFromNumericMonth(month: number): string {
  return new Date(0, month - 1).toLocaleString('en-US', { month: 'long' });
}

/**
 * Given a month string, returns the numeric month (1-12).
 * @throws Error if the month string is invalid. See getMonthsInOrder for valid month strings.
 */
export function getNumericMonthFromMonthString(month: string): number {
  const months = getMonthsInOrder();
  const index = months.indexOf(month) + 1;

  if (index !== -1) {
    return index;
  }
  throw new Error('Invalid month name');
}

/**
 * Given a numeric month (1-12) and year, returns the number of days in that month.
 */
export function getDaysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}
